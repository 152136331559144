<template>
  <div class="herder">
    <div class="informatin-logo">
      <div><img :src="logo" alt="" class="oiltour_lvxie_logo" /></div>
    </div>

    <div v-if="!isback" class="herdertext" @click="tohome">返回</div>
  </div>
</template>

<script>
import {  ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    noback: {
      type: Boolean,
      default: false
    }
  },
  emits: ['callback'],
  setup(props, ctx) {
    const isback = ref(props.noback)
    const router = useRouter()
    // const logo = require('@/assets/logo.png')
    const logo = require('@/assets/oiltour_lvxie_logo.png')
    const logotitle = require('@/assets/logotitle.png')

    const tohome = () => {
      if (!props.name) {
        router.go(-1)
      } else {
        router.push({ name: props.name })
      }
      ctx.emit('callback')
    }
    return {
      tohome,
      isback,
      logo,
      logotitle
    }
  }
}
</script>

<style lang="less" scoped>
.herder {
  display: flex;
  height: 80px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: #eff3fe;
  width: 100%;
}
.informatin-logo {
  padding: 0.4rem;
  img{
    position: relative;
    top: 15px;
  }
  .oiltour_lvxie_logo{
    width: 120px;
    height: 24px;
  }
}
.herdertext {
  line-height: 80px;
  color: #000;
  font-size: 14px;
  padding-right: 0.4rem;
}
</style>