<template>
  <div class="News">
    <div class="swiper-warp">
      <nHeader :name="'information'"></nHeader>

      <div class="news-list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            @offset="10"
          >
            <div
              class="newslist"
              v-for="item in newsData"
              :key="item.index"
              @click="tocontent(item.id)"
            >
              <div class="newstitle">
                <h3>{{ item.contentTitle }}</h3>
              </div>
              <div class="newsimg"><img :src="item.contentImg" alt="" /></div>
              <div class="newscontent">
                <div class="newsdescribe">
                  <span>{{ item.contentDescription }}</span>
                </div>
                <div class="news-author">
                  <span>{{ item.contentAuthor }}</span>

                  <span>{{ item.contentDatetime }}</span>
                </div>
                <!-- <div class="newslabel">
              <van-tag color="#d9ecf4" text-color="#03b5ff">标签</van-tag>
              <van-tag color="#d9ecf4" text-color="#03b5ff">标签</van-tag>
            </div> -->
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { queryPageContent } from '@/service/Information'
import nHeader from '@/components/nHerder'

export default {
  components: {
    nHeader
  },
  setup() {
    const router = useRouter()

    const state = reactive({
      img1: require('@/assets/img1.png'),
      newsData: [],
      currPage: 1,
      totalPage: 0,
      loading: false,
      finished: false,
      refreshing: false
    })

    onMounted(() => {
      onRefresh()
    })
    const newsList = async () => {
      const {
        data,
        data: { list }
      } = await queryPageContent({
        params: { categoryTitle: '早报 ', page: state.currPage, size: 5 }
      })
      state.newsData = state.newsData.concat(list)
      state.totalPage = data.totalPage
      state.loading = false
      if (state.currPage >= data.totalPage) state.finished = true
    }

    const onLoad = () => {
      if (!state.refreshing && state.currPage < state.totalPage) {
        state.currPage = state.currPage + 1
      }
      if (state.refreshing) {
        state.newsData = []
        state.refreshing = false
      }
      newsList()
    }

    const onRefresh = () => {
      state.refreshing = true
      state.finished = false
      state.loading = true
      state.currPage = 1
      onLoad()
    }

    const tocontent = (id) => {
      router.push({ name: 'newscontent', query: { id, url: 'news' } })
    }
    return {
      ...toRefs(state),
      newsList,
      tocontent,
      onRefresh,
      onLoad
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-warp {
  background-image: linear-gradient(180deg, #eff3fe, #eff3fe, #ffffff, #ffffff);
}
.newsimg {
  height: 180px;
  overflow: hidden;
}
.news-list {
  margin-top: 80px;
  padding: 0 0.1rem 0.2rem 0.1rem;
  height: calc(~'(100vh - 90px)');
  overflow: hidden;
  overflow-y: scroll;

  .newslist {
    padding: 0.2rem;
    margin: 0.2rem 0;
    color: #000;
    box-shadow: 2px 2px 5px #c1c1c1;
    border-radius: 5px;
    h3 {
      margin: 0;
      margin-bottom: 0.2rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical; //盒子中内容竖直排列
    }
    img {
      width: 100%;
    }
    .newscontent {
      padding: 0.1rem 0;

      .newsdescribe {
        span {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 3; //行数
          line-clamp: 3;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
          line-height: 0.5rem;
        }
      }
      .news-author {
        display: flex;
        line-height: 20px;
        justify-content: space-between;
        color: #999;
        margin: 5px 0;
      }
      .newslabel {
        span {
          margin-right: 5px;
          border: none;
        }
      }
    }
  }
}
</style>