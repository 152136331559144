import axios from '../utils/axios'


export function queryPageContent(params) {
  return axios.get('/info/content/queryPageContent', params);
}

export function content(id) {
  return axios.get(`/info/content/${id}`);
}


export function getLibrary(params) {
  return axios.get('/getLibrary', params);
}


export function getLibrarys(params) {
  return axios.get('/getLibrarys', params);
}

export function priceList() {
  return axios.get('/info/wogp/region/list');
}